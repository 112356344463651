/* src/styles.css */
/* Reset and base styles */
body, h1, p, button {
    margin: 0;
    padding: 0;
    font-family: 'Work Sans', 'Roboto', sans-serif;

  }

  h1 {
    font-size: 4em;
    background: linear-gradient(135deg, #FF726D 5%, #FF4DA9 45%, #CD42D7 85%);
    background-clip: text;
    color: rgba(0,0,0,0);
}
  
  h2 {
    font-size: 2.5em;
  }

  /* Header styles */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #333;
    color: #fff;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
/* styles.css */

.social-nav a {
    color: #fff; /* White color for icons */
    margin: 0 10px;
  }
  
  .social-nav a:hover {
    color: #ddd; /* Lighter color on hover */
  }
  
  /* Size of the icons */
  .social-nav .fa {
    font-size: 24px;
  }
  
  
  /* Footer styles */
  .footer {
    position: sticky;
    bottom: 0;
    background-color: #333;
    padding: 10px 0;
    text-align: center;
    font-weight: 300;
    font-size: 0.75em;
    letter-spacing: 0.1em;


  }
  
  .footer-nav a {
    color: #fff;
    text-decoration: none;
    margin: 0 20px;
  }
  
  /* Home page styles */

  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    
  }
  .home-hero-section {
    background-image: url('./teljalis.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-width: 100%;
    height: 110vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .home-content-wrapper {
    text-align: center;
    color: #fff;
    margin-top: 5%;
    margin-left: 17%;
    margin-right: 17%;
    padding-left: 3%;  
    padding-right: 3%;
    padding-top: 2%;
    margin-bottom: 5%;
    flex-grow: 1;
    background-color: rgba(51,51,51,0.4);
}

.home-button {
    padding-top: 5%;
    flex-grow: 2;

}

.home-section-2 {
    padding-left: 17%;
    padding-right: 17%;
    padding-bottom: 5%;
    background-color: #381535;
    color: #fff;
}

.home-section-3 {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 5%;
    background-color: #3e3144;
    color: #fff;
}

.home-section-4 {
    padding-left: 0%;
    padding-right: 0%;
    padding-bottom: 5%;
    background-color: #ffffff;
    color: #1d1d1d;
}

.home-section-5 {
    width:100%;
    padding-bottom: 5%;
    background-color: #2a292c;
    color: #fff;
}
  
    .creates-redirect-message{
        height: 100vh;
        background-color: #333;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;

    }

  button {
    background: linear-gradient(135deg, #FF726D 5%, #FF4DA9 45%, #CD42D7 85%);
    color: #fff;
    border: 0.125em;
    padding: 0.5em 2em;
    cursor: pointer;
    min-height: 2.75em;
    font-size: 1em;
    margin-top: 20px;
    transition: background-color 0.3s;
    border-radius: 0.2em;
  }
  
  button:hover {
    background-color: #FFA000;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 24px;
    }
  
    .hero-content p {
      font-size: 18px;
    }
  
    .footer-nav a {
      font-size: 18px;
    }
  }
  

  /* App.css */
table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #FF726D;
  }
  
  tbody tr:hover {
    background-color: #ff726d6b;
  }
  
  /* Services.css */
.services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
  }
  
  .service-card {
    flex: 1;
    min-width: 250px; /* Minimum width of a card to ensure readability */
    max-width: 300px; /* Maximum width to maintain uniformity */
    background-color: #3f3644;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .service-card h3 {
    margin-top: 0;
    background: linear-gradient(135deg, #FF726D 5%, #FF4DA9 45%, #CD42D7 85%);
    background-clip: text;
    color: rgba(0,0,0,0);
  }
  
  .service-card p {
    color: #ffffff;
  }
  
  /* LogosSection.css */
.logos-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px; /* Adjust based on your design preference */
    padding: 20px;
  }
  
  .logo img {
    width: auto; /* Adjust based on the size of logos */
    max-height: 3em; /* Maintain aspect ratio */
    filter: grayscale(100%); /* Optional: makes logos grayscale */
    transition: filter 0.3s ease;
  
    /* Enhance visibility on hover */
  }
  
  .logo img:hover {
    filter: grayscale(0);
  }
  
  .contactpage-wrapper {
    padding-top: 0%;

  }

  .contactpage-wrapper h1 {
    margin-bottom: 5%;
  }